import React from 'react'
// import Container from '../../components/container'
import Header from '../../components/header'
import Footer from '../../components/footer_de'
import * as style from '../../components/css/impressum.module.css'
import Container from '../../components/container'
import SEO from '../../components/seo'

const impressum = () => {
    return (
        <dev className={style.main}>
            <SEO title="Impressum" description="Impressum und Datenschutzerklärung zur Webseite Deutsch"/>
            {/* <Header captionText="Impressum" /> */}
            <Header />
                <Container>
                    <h2>Impressum</h2>
                    <p>Marco Deubel</p>
                    <div>
                        Käthe-Kollwitz-Straße 29
                    </div>
                    <div>
                        63110 Rodgau
                    </div>
                    <p>
                        mail [at] universaltimecomplication [dot] de
                    </p>
                    <div>

                    </div>
                    {/* <div className={style.websitepp}> */}
                    <div>
                        <h2>Datenschutzerklärung Webseite</h2>
                        <p>
                            <div>Der Web-Server des Hosters speichert Log-Dateien über Zugriffe auf die Webseite, die auch IP-Adresse enthalten.</div>
                            <div>IP-Adressen werden nach 7 Tagen unwiderruflich anonymisiert.</div>
                            <div>Die Logs werden maximal 6 Monate aufbewahrt.</div>
                            <div>Anonymisierte Logs dienen der statistischen Auswertung.</div>
                            <div>Nicht anonymisierte (IPs max 7 Tage) zum Erkennen von Angriffen.</div>
                        </p>
                        <p>Darüber hinaus werden auf der Webseite keine Daten gespeichert oder ausgewertet.</p>
                    </div>
                </Container>
            <Footer />
        </dev>
    )
}

export default impressum
