import React from 'react'
import * as styles from './css/footer.module.css'
import { Link } from 'gatsby'
import Container from './container_footer'

const Footer = (props) => {
    return (
        <Container>
            <footer className={styles.main}>
                <p className={styles.copyright}>Copyright &copy; 2021 by Marco Deubel</p>
                <p> 
                <Link className={styles.link} to="/de">App</Link> 
                &nbsp;&middot;&nbsp;
                <Link className={styles.link} to="/de/impressum">Impressum/Datenschutz</Link>
                &nbsp;&middot;&nbsp;
                <Link className={styles.link} to="/de/privacypolicy">Privacy Policy App</Link>
                &nbsp;&middot;&nbsp;
                <Link className={styles.link} to="/">Englisch</Link>
                </p>
            </footer>
        </Container>
    )
}

export default Footer
